import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { notification, ConfigProvider } from "antd";
import type {
  ArgsProps,
  NotificationPlacement,
} from "antd/es/notification/interface";
import { notificationManager } from "./notificationManager";

type NotificationApi = ReturnType<typeof notification.useNotification>[0];

interface NotificationContextProps {
  notify: (args: ArgsProps) => void;
}

const NotificationContext = createContext<NotificationContextProps | null>(
  null
);

const defaultNotifyProps: Partial<ArgsProps> = {
  duration: 10,
  placement: "bottomRight" as NotificationPlacement,
  showProgress: true,
  pauseOnHover: false,
};

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [api, contextHolder] = notification.useNotification({
    stack: {
      threshold: 1,
    },
    maxCount: 1,
  });

  const notify = (args: ArgsProps) => {
    api.open({
      ...defaultNotifyProps,
      ...args,
      className:
        "bg-customGray/80 relative border-small border-divider shadow-custom2 rounded-[14px]",
    });
  };

  useEffect(() => {
    notificationManager.setNotifyFunction(notify);
  }, [notify]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Notification: {
            zIndexPopup: 999999,
          },
        },
      }}
    >
      <NotificationContext.Provider value={{ notify }}>
        {contextHolder}
        {children}
      </NotificationContext.Provider>
    </ConfigProvider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context.notify;
};
