import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import apiClient from "../utils/apiClient";
import i18n from "../i18n";
import userStore from "./UserStore";
import { notificationManager } from "components/UI/notificationManager";

import Terminal from "locaLapps/Terminal/Terminal";
import Settings from "locaLapps/Settings/Settings";
import MarioKart from "locaLapps/MarioKart/MarioKart";
import OpenURLApp from "locaLapps/OpenURLApp/OpenURLApp";
import Doom from "locaLapps/Doom/Doom";
import Test from "pages/Test/Test";
import Osynk from "locaLapps/Osynk/Osynk";
import BackgroundSelector from "components/BackgroundSelector/BackgroundSelector";
import SynkStore from "locaLapps/SynkStore/SynkStore";
import SynkWS from "locaLapps/SynkWS/SynkWS";
import DeepSeek from "locaLapps/DeepSeek/DeepSeek";
import SynkCrypt from "locaLapps/SynkCrypt/SynkCrypt";
import SynkSmash from "locaLapps/SynkSmash/SynkSmash";
import SynkPass from "locaLapps/SynkPass/SynkPass";

import appsData from "locaLapps/SynkStore/data/appsData.json";

import SettingsLocalStore from "locaLapps/Settings/store/SettingsLocalStore";
import SynkStoreLocalStore from "locaLapps/SynkStore/store/SynkStoreLocalStore";
import { OsynkLocalStore } from "locaLapps/Osynk/store/OsynkLocalStore";
import { NoVncLocalStore } from "components/NoVNCViewer/store/NoVncLocalStore";

export interface AppDefinition {
  appName: string;
  title?: string;
  image?: string;
  category?: string;
  developer?: string;
  description?: string;
  tags?: string[];
  summary?: string;
  componentName?: string;
  installedByDefault?: boolean;
  notUninstallable?: boolean;
  hideFromStore?: boolean;
  requiresBackend?: boolean;
  noScrollArea?: boolean;
  defaultWindowSize?: { width: number; height: number };
  backgroundColor?: string;
  progressBarColor?: string;
  [key: string]: any;
}

export interface InstalledApp extends AppDefinition {
  component?: React.ComponentType<any>;
}

export interface RunningAppInstance {
  localInstanceId: string;
  backendInstanceId?: string;
  appDefinition: InstalledApp;
  minimized: boolean;
  position: { x: number; y: number };
  windowSize: { width: number; height: number };
  iframeSize: { width: number; height: number };
  zIndex: number;
  isLoading: boolean;
  isMaximized: boolean;
  screenUrl: string | null;
  previewImage: string | null;
  localStore?: any;
  shouldCapturePreview?: boolean;
}

class AppStore {
  allApps: AppDefinition[] = [];
  installedApps: InstalledApp[] = [];
  runningApps: Map<string, RunningAppInstance> = new Map();

  isLoading = false;
  error = "";
  highestZIndex = 100;
  delayBeforeDisplay = 3000;
  hasHydrated = false;

  componentMap: Record<string, React.ComponentType<any>> = {
    Terminal,
    Settings,
    MarioKart,
    OpenURLApp,
    Doom,
    Test,
    Osynk,
    SynkStore,
    BackgroundSelector,
    SynkWS,
    DeepSeek,
    SynkCrypt,
    SynkSmash,
    SynkPass,
  };

  constructor() {
    makeAutoObservable(this);
    this.initializeAllApps();
    this.loadInstalledApps();
  }

  get availableApps(): InstalledApp[] {
    return this.installedApps;
  }

  private isAppReleasedAndPresentInStore(def: AppDefinition) {
    if (!def.releaseDate) return true;
    const now = new Date();
    const releaseDate = new Date(def.releaseDate);
    return releaseDate <= now;
  }

  initializeAllApps() {
    const rawApps = appsData as AppDefinition[];
    this.allApps = rawApps.filter((app) => {
      return this.isAppReleasedAndPresentInStore(app);
    });
  }

  loadInstalledApps() {
    if (this.hasHydrated) return;
    this.hasHydrated = true;

    const data = localStorage.getItem("installedApps");
    const stored = data ? JSON.parse(data) : [];

    const validInstalledApps: AppDefinition[] = [];
    for (const storedApp of stored) {
      const def = this.allApps.find((x) => x.appName === storedApp.appName);
      if (!def) continue;
      if (!this.isAppReleasedAndPresentInStore(def)) continue;
      validInstalledApps.push(def);
    }

    const defaultApps = this.allApps.filter(
      (def) =>
        def.installedByDefault && this.isAppReleasedAndPresentInStore(def)
    );

    const combined = [...validInstalledApps, ...defaultApps];
    const unique = combined.reduce((acc, def) => {
      if (!acc.some((x) => x.appName === def.appName)) {
        acc.push(def);
      }
      return acc;
    }, [] as AppDefinition[]);

    this.installedApps = unique.map((def) => this.toInstalledApp(def));

    this.persistInstalledApps();
  }

  toInstalledApp(def: AppDefinition): InstalledApp {
    let comp: React.ComponentType<any> | undefined;

    if (!def.requiresBackend && def.componentName) {
      comp = this.componentMap[def.componentName] || OpenURLApp;
    }
    return {
      ...def,
      component: comp,
    };
  }

  setShouldCapturePreview(localInstanceId: string, capture: boolean) {
    const inst = this.runningApps.get(localInstanceId);
    if (inst) {
      inst.shouldCapturePreview = capture;
    }
  }

  persistInstalledApps() {
    const result = this.installedApps.map((a) => ({ appName: a.appName }));
    localStorage.setItem("installedApps", JSON.stringify(result));
  }

  installApp(def: AppDefinition) {
    if (def.notUninstallable) return;
    const exists = this.installedApps.find((a) => a.appName === def.appName);
    if (!exists) {
      const newInstalled = this.toInstalledApp(def);
      this.installedApps.push(newInstalled);
      this.persistInstalledApps();
    }
  }

  uninstallApp(def: AppDefinition) {
    if (def.notUninstallable) return;
    this.closeAllInstancesOfApp(def.appName);
    this.installedApps = this.installedApps.filter(
      (x) => x.appName !== def.appName
    );
    this.persistInstalledApps();
  }

  closeAllInstancesOfApp(appName: string) {
    const ids = Array.from(this.runningApps.values())
      .filter((i) => i.appDefinition.appName === appName)
      .map((i) => i.localInstanceId);
    for (const id of ids) {
      this.closeAppInstance(id);
    }
  }

  setPreviewImage(localInstanceId: string, dataUrl: string) {
    const inst = this.runningApps.get(localInstanceId);
    if (inst) {
      inst.previewImage = dataUrl;
    }
  }

  setIsMaximized(localInstanceId: string, val: boolean) {
    const inst = this.runningApps.get(localInstanceId);
    if (inst) {
      inst.isMaximized = val;
    }
  }

  updateAppPositionAndSize(
    localInstanceId: string,
    pos: { x: number; y: number },
    winSize: { width: number; height: number },
    iframeSize: { width: number; height: number }
  ) {
    const inst = this.runningApps.get(localInstanceId);
    if (inst) {
      inst.position = pos;
      inst.windowSize = winSize;
      inst.iframeSize = iframeSize;
    }
  }

  bringAppToFront(localInstanceId: string) {
    const inst = this.runningApps.get(localInstanceId);
    if (inst) {
      this.highestZIndex += 1;
      inst.zIndex = this.highestZIndex;
    }
  }

  minimizeAppInstance(localInstanceId: string) {
    const inst = this.runningApps.get(localInstanceId);
    if (inst) {
      inst.minimized = true;
    }
  }

  restoreAppInstance(localInstanceId: string) {
    const inst = this.runningApps.get(localInstanceId);
    if (inst) {
      inst.minimized = false;
      this.bringAppToFront(localInstanceId);
    }
  }

  async fetchApps() {
    this.isLoading = true;
    this.error = "";

    try {
      const res = await apiClient.get("/v1/apps");
      const data = res.data.apps;

      runInAction(() => {
        const newRunning = new Map<string, RunningAppInstance>();

        for (const backendApp of data) {
          for (const inst of backendApp.appInstances) {
            if (
              inst.status === "Running" &&
              inst.screen_url &&
              inst.appInstanceId
            ) {
              const def = this.allApps.find(
                (x) =>
                  x.appName.toLowerCase() === backendApp.appName.toLowerCase()
              );
              if (!def) continue;

              const installedApp = this.toInstalledApp(def);

              const found = Array.from(this.runningApps.values()).find(
                (x) => x.backendInstanceId === inst.appInstanceId
              );

              const defaultW = installedApp.defaultWindowSize?.width ?? 800;
              const defaultH = installedApp.defaultWindowSize?.height ?? 600;

              const finalWidth = inst.window_size?.width ?? defaultW;
              const finalHeight = inst.window_size?.height ?? defaultH;

              if (found) {
                found.screenUrl = inst.screen_url;
                found.isLoading = false;

                if (!found.localStore && installedApp.requiresBackend) {
                  found.localStore = new NoVncLocalStore(found.localInstanceId);
                }

                newRunning.set(found.localInstanceId, found);
              } else {
                const z = ++this.highestZIndex;
                const localInstanceId = inst.appInstanceId;

                let localStore;
                if (installedApp.requiresBackend) {
                  localStore = new NoVncLocalStore(localInstanceId);
                }

                const screenW = window.innerWidth || 1920;
                const screenH = window.innerHeight || 1080;

                const clampedWidth = Math.min(finalWidth, screenW);
                const clampedHeight = Math.min(finalHeight, screenH);

                const maxX = Math.max(0, screenW - clampedWidth);
                const maxY = Math.max(0, screenH - clampedHeight);
                const randomX = Math.floor(Math.random() * (maxX + 1));
                const randomY = Math.floor(Math.random() * (maxY + 1));

                newRunning.set(localInstanceId, {
                  localInstanceId,
                  backendInstanceId: inst.appInstanceId,
                  appDefinition: installedApp,
                  minimized: false,

                  position: { x: randomX, y: randomY },

                  windowSize: { width: clampedWidth, height: clampedHeight },

                  iframeSize: {
                    width: clampedWidth,
                    height: clampedHeight - 40,
                  },

                  zIndex: z,
                  isLoading: false,
                  isMaximized: false,
                  screenUrl: inst.screen_url,
                  previewImage: null,
                  localStore,
                });
              }
            }
          }
        }

        this.runningApps = newRunning;
      });
    } catch (e: any) {
      this.handleError(e, "error_loading_apps");
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  createLocalStoreForApp(app: InstalledApp, localId: string, props?: any) {
    if (!app.requiresBackend) {
      if (!app.componentName) return undefined;
      switch (app.componentName) {
        case "Settings":
          return new SettingsLocalStore(localId, props?.initialTab);
        case "SynkStore":
          return new SynkStoreLocalStore(localId);
        case "Osynk":
          return new OsynkLocalStore(localId);
        default:
          return undefined;
      }
    } else {
      return new NoVncLocalStore(localId);
    }
  }

  async launchApp(appName: string, props?: any) {
    const app = this.installedApps.find(
      (x) => x.appName.toLowerCase() === appName.toLowerCase()
    );
    if (!app) {
      this.handleError(null, "error_launching_app");
      return;
    }

    const localId = `local-${uuidv4()}`;
    const z = ++this.highestZIndex;

    let { width, height } = app.defaultWindowSize || {
      width: 800,
      height: 600,
    };

    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const isMobile = screenW < 600;
    const DOCK_RESERVED_SPACE = 80;

    if (isMobile) {
      width = screenW;

      height = Math.min(height, screenH - DOCK_RESERVED_SPACE);
    } else {
      if (width > screenW) width = screenW;
      if (height > screenH) height = screenH;
    }

    let cx, cy;
    if (isMobile) {
      cx = 0;
      cy = 0;
    } else {
      cx = Math.max(0, (screenW - width) / 2);
      cy = Math.max(0, (screenH - height) / 2);
    }

    const localStore = this.createLocalStoreForApp(app, localId, props);

    this.runningApps.set(localId, {
      localInstanceId: localId,
      appDefinition: app,
      minimized: false,
      position: { x: cx, y: cy },
      windowSize: { width, height },

      iframeSize: { width, height: height - 40 },
      zIndex: z,
      isLoading: !!app.requiresBackend,
      isMaximized: false,
      screenUrl: null,
      backendInstanceId: undefined,
      previewImage: null,
      localStore,
    });

    if (!app.requiresBackend) {
      return;
    }

    try {
      const r = await apiClient.post(`/v1/apps/${appName.toLowerCase()}/start`);
      const { success, details } = r.data;
      if (success && details.appInstanceId && details.screen_url) {
        this.pollRemoteApp(localId, details.appInstanceId, details.screen_url);
      } else {
        this.handleError(null, "error_launching_app");
      }
    } catch (e: any) {
      this.handleError(e, "error_launching_app");
    }
  }

  async pollRemoteApp(localId: string, backendId: string, url: string) {
    try {
      const instance = this.runningApps.get(localId);
      if (instance) {
        instance.backendInstanceId = backendId;
      }

      const checkStatus = async () => {
        try {
          const r = await apiClient.get("/v1/apps");
          const data = r.data.apps;
          let foundApp: any = null;
          for (const a of data) {
            const inst = a.appInstances.find(
              (i: any) =>
                i.appInstanceId === backendId && i.status === "Running"
            );
            if (inst) {
              foundApp = { app: a, inst };
              break;
            }
          }
          if (foundApp) {
            await new Promise((res) =>
              setTimeout(res, this.delayBeforeDisplay)
            );
            runInAction(() => {
              const current = this.runningApps.get(localId);
              if (current) {
                current.screenUrl = foundApp.inst.screen_url;
              }
            });
          } else {
            setTimeout(checkStatus, 1000);
          }
        } catch (err) {
          this.handleError(err, "error_checking_app_status");
        }
      };
      checkStatus();
    } catch (err) {
      this.handleError(err, "error_checking_app_status");
    }
  }

  setInstanceConnected(localId: string) {
    const instance = this.runningApps.get(localId);
    if (instance) {
      instance.isLoading = false;
    }
  }

  async closeAppInstance(localInstanceId: string) {
    const current = this.runningApps.get(localInstanceId);
    if (!current) return;

    this.runningApps.delete(localInstanceId);

    if (current.backendInstanceId) {
      try {
        const r = await apiClient.post(
          `/v1/apps/${current.backendInstanceId}/stop`
        );
        if (!r.data.success) {
          this.runningApps.set(localInstanceId, current);
          this.handleError(null, "error_closing_specific_app");
        }
      } catch (e) {
        this.runningApps.set(localInstanceId, current);
        this.handleError(e, "error_closing_specific_app");
      }
    }
  }

  focusOrLaunchApp(appName: string, props?: any) {
    const list = Array.from(this.runningApps.values()).filter(
      (x) => x.appDefinition.appName.toLowerCase() === appName.toLowerCase()
    );

    if (list.length > 0) {
      const inst = list[0];

      if (
        inst.appDefinition.componentName === "Settings" &&
        props?.initialTab
      ) {
        inst.localStore.setSelectedTab(props.initialTab);
      }

      this.restoreAppInstance(inst.localInstanceId);
      this.bringAppToFront(inst.localInstanceId);
    } else {
      this.launchApp(appName, props);
    }
  }

  async handleVPNChange(_statusKey: string) {
    //empty for now
  }

  handleError(err: any, key: string) {
    const message =
      userStore.debugMode && err?.message ? err.message : i18n.t(key);
    notificationManager.notify({
      message: i18n.t("error"),
      description: message,
      duration: 30,
    });
  }
}

const appStore = new AppStore();
export default appStore;
