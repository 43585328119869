"use client";

import React from "react";
import {
  Card,
  CardBody,
  Badge,
  Avatar,
  Button,
  Tabs,
  Tab,
  Input,
  Textarea,
  Spacer,
  Tooltip,
  Snippet,
  Progress,
} from "@nextui-org/react";
import { ExternalLink, User, PenIcon } from "lucide-react";
import { observer } from "mobx-react-lite";
import userStore from "stores/UserStore";
import { cn } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import IpAddressDisplay from "components/IpAddressDisplay/IpAddressDisplay";

const ProfileTab = observer(() => {
  const { t } = useTranslation();
  const {
    isAuthenticated,
    address,
    picture,
    synkBalance,
    currentLanguage,
    setLanguage,
    formattedTokenRemainingTime,
    tokenProgress,
    tokenColor,
    ipAddress,
  } = userStore;

  const handleOpenEtherscan = () => {
    if (address) {
      const etherscanUrl = `https://etherscan.io/address/${address}`;
      window.open(etherscanUrl, "_blank", "noopener,noreferrer");
    }
  };

  const handleCopyAddress = async () => {
    if (address) {
      try {
        await navigator.clipboard.writeText(address);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    }
  };

  return (
    <div className="space-y-6">
      {/* Profile Section */}
      <div>
        <p className="text-base font-medium text-default-700">{t("profile")}</p>
        <p className="mt-1 text-sm font-normal text-default-400">
          {isAuthenticated ? t("your_profile") : t("guest")}
        </p>

        <Card className="mt-4 bg-default-100" shadow="none">
          <CardBody>
            {isAuthenticated ? (
              <div className="flex items-center gap-4">
                <Badge
                  disableOutline
                  classNames={{
                    badge: "w-5 h-5",
                  }}
                  content={
                    <Button
                      isIconOnly
                      className="h-5 w-5 min-w-5 bg-background p-0 text-default-500"
                      radius="full"
                      size="sm"
                      variant="bordered"
                      aria-label={t("edit_profile")}
                    >
                      <PenIcon className="h-[9px] w-[9px]" />
                    </Button>
                  }
                  placement="bottom-right"
                  shape="circle"
                >
                  <Avatar
                    className="h-16 w-16"
                    isBordered
                    radius="full"
                    color={tokenColor}
                    src={picture || undefined}
                    alt={t("user_avatar")}
                    showFallback
                    fallback={<User className="w-5 h-5" fill="currentColor" />}
                  />
                </Badge>
                <div>
                  <p className="text-sm font-medium text-default-600">
                    {t("synk_user")}
                  </p>
                  <div className="flex items-center gap-2">
                    <Snippet
                      variant="bordered"
                      hideSymbol={true}
                      onClick={handleCopyAddress}
                      onDoubleClick={handleCopyAddress}
                      tooltipProps={{
                        content: t("copy_address"),
                        disableAnimation: true,
                        placement: "top",
                        offset: 7,
                      }}
                      classNames={{
                        base: ["border-none", "px-0", "py-0"],
                        pre: ["w-[160px]", "truncate"],
                        copyButton: ["data-[hover=true]:bg-lightColor/10"],
                      }}
                    >
                      {address}
                    </Snippet>
                    <Tooltip placement="top" content={t("open_on_etherscan")}>
                      <Button
                        isIconOnly
                        onClick={handleOpenEtherscan}
                        aria-label={t("open_on_etherscan")}
                        variant="light"
                        className={cn(
                          "group inline-flex items-center justify-center",
                          "box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased",
                          "overflow-hidden tap-highlight-transparent",
                          "data-[pressed=true]:scale-[0.97] outline-none",
                          "data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2",
                          "data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2",
                          "gap-2 rounded-small px-0 !gap-0",
                          "transition-transform-colors-opacity motion-reduce:transition-none",
                          "bg-transparent min-w-8 w-8 h-8 group relative z-10",
                          "text-large text-inherit data-[hover=true]:bg-lightColor/10"
                        )}
                      >
                        <ExternalLink className="w-4 h-4" />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-4">
                <Avatar
                  className="h-16 w-16"
                  radius="full"
                  showFallback
                  fallback={<User className="w-5 h-5" fill="currentColor" />}
                />
                <div>
                  <p className="text-sm font-medium text-default-600">
                    {t("anonyme")}
                  </p>
                  <p className="text-xs text-default-400">{t("guest_user")}</p>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>

      <Spacer y={4} />

      {/* IP Address Section */}
      <div>
        <p className="text-base font-medium text-default-700">
          {t("ip_address")}
        </p>
        <Card className="mt-4 bg-default-100" shadow="none">
          <CardBody>
            <IpAddressDisplay
              ipAddress={ipAddress}
              showRefreshButton={true}
              onRefresh={() => userStore.fetchIpAddress()}
            />
          </CardBody>
        </Card>
      </div>

      <Spacer y={4} />

      {/* SYNK Balance Section - Only for authenticated users */}
      {isAuthenticated && (
        <>
          <div>
            <p className="text-base font-medium text-default-700">
              {t("synk_balance")}
            </p>
            <p className="mt-1 text-sm font-normal text-default-400">
              {t("synk_remaining")}
            </p>
            <Card className="mt-4 bg-default-100" shadow="none">
              <CardBody>
                <div className="rounded-large bg-default-100">
                  <div className="flex items-center justify-between gap-2 px-1 py-1">
                    <div className="flex items-center gap-3">
                      <div>
                        <p className="text-xs font-medium text-default-600">
                          {t("synk_remaining")}
                        </p>
                        <p className="text-white">{`${synkBalance} SYNK`}</p>
                      </div>
                    </div>
                    <Button
                      radius="md"
                      size="sm"
                      disabled
                      color="success"
                      variant="shadow"
                    >
                      {t("buy_synk")}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <Spacer y={4} />
        </>
      )}

      {/* Session Expiration Section */}
      <div>
        <p className="text-base font-medium text-default-700">
          {t("session_expiration")}
        </p>
        <p className="mt-1 text-sm font-normal text-default-400">
          {t("session_time_remaining")}
        </p>
        <Card className="mt-4 bg-default-100" shadow="none">
          <CardBody>
            <div className="rounded-large bg-default-100">
              <div className="flex items-center justify-between gap-5 px-1 py-1">
                <div className="flex items-center gap-3 min-w-[100px]">
                  <div>
                    <p className="text-xs font-medium text-default-600">
                      {t("token_expires_in")}
                    </p>
                    <p className="text-white">{formattedTokenRemainingTime}</p>
                  </div>
                </div>
                <Progress
                  size="md"
                  radius="md"
                  value={tokenProgress}
                  color={tokenColor}
                  showValueLabel={true}
                  aria-label={t("token_progress")}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <Spacer y={4} />

      {/* Language Selection Section */}
      <div>
        <p className="text-base font-medium text-default-700">
          {t("language")}
        </p>
        <p className="mt-1 text-sm font-normal text-default-400">
          {t("language_options")}
        </p>
        <Card className="mt-4 bg-default-100" shadow="none">
          <CardBody>
            <Tabs
              aria-label={t("language_options")}
              color="success"
              variant="solid"
              selectedKey={currentLanguage}
              onSelectionChange={(key) => setLanguage(key as string)}
              classNames={{ base: "w-full mt-1", tabList: "w-full" }}
            >
              <Tab key="en" title={t("english")} />
              <Tab key="zh" title={t("chinese")} />
            </Tabs>
          </CardBody>
        </Card>
      </div>
    </div>
  );
});

ProfileTab.displayName = "ProfileTab";

export default ProfileTab;
