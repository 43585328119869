import React from "react";

const DeepSeek: React.FC = () => {
  return (
    <div className="relative w-full h-full">
      <iframe
        src="https://deepseek.synk.ws"
        title="DeepSeek"
        className="w-full h-full border-none"
        sandbox="allow-scripts allow-same-origin allow-popups"
        allow="camera; microphone; fullscreen"
      ></iframe>
    </div>
  );
};

export default DeepSeek;
