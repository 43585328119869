import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import appStore, { RunningAppInstance, AppDefinition } from "stores/AppStore";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";
import LinkPreview from "components/UI/link-preview";
import { cn } from "lib/utils";
import { useIsMobile } from "hook/useIsMobile";
import { Spotlight } from "components/UI/Spotlight";
import { X } from "lucide-react";

import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
} from "components/UI/context-menu";
import userStore from "stores/UserStore";

interface DockIconProps {
  appName: string;
  title: string;
  iconUrl: string;
  isRunning: boolean;
  runningInstances: RunningAppInstance[];
  isPopoverOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onIconClick: () => void;
  onInstanceClick: (instance: RunningAppInstance) => void;
  onNewInstance: () => void;
  onCloseAllInstances: () => void;
}

const DockIcon: React.FC<DockIconProps> = observer((props) => {
  const {
    appName,
    title,
    iconUrl,
    isRunning,
    runningInstances,
    isPopoverOpen,
    onOpenChange,
    onIconClick,
    onInstanceClick,
    onNewInstance,
    onCloseAllInstances,
  } = props;

  const [hovered, setHovered] = useState(false);
  const controls = useAnimation();
  const [hoveredInstanceId, setHoveredInstanceId] = useState<string | null>(
    null
  );

  const isLaunching = runningInstances.some((i) => i.isLoading);

  useEffect(() => {
    if (isLaunching) {
      controls.start({
        y: -24,
        transition: {
          repeat: Infinity,
          repeatType: "reverse",
          duration: 0.5,
        },
      });
    } else {
      controls.start({
        y: 0,
        transition: { duration: 0.5 },
      });
    }
  }, [isLaunching, controls]);

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <Popover
          isOpen={isRunning ? isPopoverOpen : false}
          onOpenChange={(open) => {
            if (isRunning) {
              onOpenChange(open);
              runningInstances.forEach((inst) => {
                if (inst.appDefinition.requiresBackend) {
                  appStore.setShouldCapturePreview(inst.localInstanceId, open);
                }
              });
            }
          }}
          placement="top"
          offset={0}
          classNames={{
            base: "before:shadow-custom",
            content:
              "p-0 bg-lightColor/10 border-small border-divider shadow-custom relative",
          }}
          shouldFlip
          backdrop="opaque"
        >
          <PopoverTrigger>
            <motion.div
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={onIconClick}
              animate={controls}
              className="aspect-square rounded-full flex items-center justify-center relative hover:bg-lightColor/10 p-3 cursor-pointer transition-colors duration-200"
            >
              <AnimatePresence>
                {hovered && (
                  <motion.div
                    initial={{ opacity: 0, y: 10, x: "-50%" }}
                    animate={{ opacity: 1, y: 0, x: "-50%" }}
                    exit={{ opacity: 0, y: 5, x: "-50%" }}
                    className="px-2 py-0.5 whitespace-pre rounded-md border bg-customGray border-neutral-900 text-white absolute left-1/2 -translate-x-1/2 -top-8 w-fit text-xs"
                  >
                    {title}
                  </motion.div>
                )}
              </AnimatePresence>
              <div
                className="flex items-center justify-center transition-opacity duration-200"
                style={{ width: 35, height: 35 }}
              >
                <img className="rounded-xl" src={iconUrl} alt={title} />
              </div>
              <AnimatePresence mode="popLayout">
                {isRunning && (
                  <motion.div
                    key="running-dot"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute -bottom-1 w-1.5 h-1.5 rounded-full bg-white"
                  />
                )}
              </AnimatePresence>
            </motion.div>
          </PopoverTrigger>
          <PopoverContent className="z-50">
            {runningInstances.length > 0 && (
              <div className="flex flex-col p-2 box-border rounded-custom backdrop-blur-buttons bg-opacity-100">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 lg:flex lg:flex-row lg:flex-wrap">
                  {runningInstances.map((instance) => {
                    const isHovered =
                      hoveredInstanceId === instance.localInstanceId;
                    return (
                      <div
                        key={instance.localInstanceId}
                        className="relative cursor-pointer flex items-center justify-center p-2 rounded-lg hover:bg-gray-400 hover:bg-opacity-10 transition-colors duration-150 overflow-hidden w-[150px] h-[100px] lg:w-[250px] lg:h-[150px]"
                        onMouseEnter={() =>
                          setHoveredInstanceId(instance.localInstanceId)
                        }
                        onMouseLeave={() => setHoveredInstanceId(null)}
                        onClick={() => onInstanceClick(instance)}
                      >
                        <LinkPreview
                          appInstance={instance}
                          className="font-bold"
                        />

                        {isHovered && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              appStore.closeAppInstance(
                                instance.localInstanceId
                              );
                            }}
                            className="
                              absolute top-2 right-2 
                              p-1 rounded-full shadow-md
                              bg-white/20 backdrop-blur-xl
                              mix-blend-difference
                              hover:bg-[#F31260]/70
                              transition-colors 
                            "
                          >
                            <X size={16} />
                          </button>
                        )}
                      </div>
                    );
                  })}
                  <div
                    className="cursor-pointer flex items-center justify-center p-2 rounded-lg hover:bg-gray-400 hover:bg-opacity-10 transition-colors duration-150 w-[150px] h-[100px] lg:w-[250px] lg:h-[150px]"
                    onClick={onNewInstance}
                  >
                    <span className="text-xl font-bold">+</span>
                  </div>
                </div>
              </div>
            )}
          </PopoverContent>
        </Popover>
      </ContextMenuTrigger>

      <ContextMenuContent className="w-48 z-[99]">
        <ContextMenuItem
          onSelect={() => {
            onNewInstance();
          }}
        >
          New Window
        </ContextMenuItem>

        {runningInstances.length > 0 && (
          <>
            <ContextMenuSeparator />
            <ContextMenuItem
              onSelect={() => {
                onCloseAllInstances();
              }}
            >
              Close All
            </ContextMenuItem>
          </>
        )}
      </ContextMenuContent>
    </ContextMenu>
  );
});

interface DockProps {
  onLaunchApp: (app: AppDefinition) => void;
}

const Dock: React.FC<DockProps> = observer(({ onLaunchApp }) => {
  const isMobile = useIsMobile();
  const [openPopovers, setOpenPopovers] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (userStore.isAuthenticated) {
      appStore.fetchApps();
    }
  }, [userStore.isAuthenticated]);

  const dockItems = appStore.availableApps.map((app) => ({
    appName: app.appName,
    title: app.title || app.appName,
    iconUrl: app.image || "",
    app: app,
  }));

  const displayedItems = isMobile ? dockItems.slice(0, 4) : dockItems;

  const togglePopover = (appName: string, open: boolean) => {
    setOpenPopovers((prev) => ({
      ...prev,
      [appName.toLowerCase()]: open,
    }));
  };

  const handleIconClick = (app: AppDefinition, isRunning: boolean) => {
    if (isRunning) {
      togglePopover(app.appName, true);
    } else {
      onLaunchApp(app);
    }
  };

  const handleNewInstance = (app: AppDefinition) => {
    togglePopover(app.appName, false);
    onLaunchApp(app);
  };

  const handleInstanceClick = (instance: RunningAppInstance) => {
    if (instance.minimized) {
      appStore.restoreAppInstance(instance.localInstanceId);
    }
    appStore.bringAppToFront(instance.localInstanceId);
    togglePopover(instance.appDefinition.appName, false);
  };

  const handleCloseAllInstances = (appName: string) => {
    appStore.closeAllInstancesOfApp(appName);
  };

  return (
    <div className="flex row justify-center items-center w-full fixed bottom-8 z-[99]">
      <div
        className={cn(
          "relative mx-auto flex h-16 gap-2 rounded-full bg-customGray/30 backdrop-blur-dock px-4 select-none border border-lightColor/10",
          "after:content-[''] after:w-1/2 after:h-px after:bg-[linear-gradient(90deg,rgba(34,220,209,0),#22dcd1B2_50%,rgba(34,220,209,0))] after:absolute after:top-[-1px] after:left-1/4 after:transition-all after:duration-500 after:ease-[cubic-bezier(.15,.85,.45,1)]"
        )}
      >
        <div className="absolute inset-0 overflow-hidden rounded-3xl border-none">
          <Spotlight
            fill="#22dcd17F"
            className="!absolute !w-[3000px] !h-[900px] left-1/2 -translate-x-1/2"
          />
        </div>
        {displayedItems.map((item) => {
          const runningInstances = Array.from(
            appStore.runningApps.values()
          ).filter(
            (inst) =>
              inst.appDefinition.appName.toLowerCase() ===
              item.appName.toLowerCase()
          );
          const isRunning = runningInstances.length > 0;

          return (
            <DockIcon
              key={item.appName}
              appName={item.appName}
              title={item.title}
              iconUrl={item.iconUrl}
              isRunning={isRunning}
              runningInstances={runningInstances}
              isPopoverOpen={!!openPopovers[item.appName.toLowerCase()]}
              onOpenChange={(open) => togglePopover(item.appName, open)}
              onIconClick={() => handleIconClick(item.app, isRunning)}
              onInstanceClick={handleInstanceClick}
              onNewInstance={() => handleNewInstance(item.app)}
              onCloseAllInstances={() => handleCloseAllInstances(item.appName)}
            />
          );
        })}
      </div>
    </div>
  );
});

export default Dock;
