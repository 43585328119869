import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

import userStore from "stores/UserStore";
import DebugDisclaimer from "../DebugDisclaimer/DebugDisclaimer";
import appStore, { AppDefinition } from "stores/AppStore";

import { useNotification } from "components/UI/NotificationProvider";
import AlertModalTokenExpire from "components/UI/AlertModalTokenExpire";
import WindowManager from "components/Window/WindowManager";
import Dock from "../Dock/Dock";
import TopBar from "../TopBar/TopBar";
import { useCustomCursor } from "utils/useCustomCursor";
import { useMagneticHover } from "utils/useMagneticHover";
import IntroSection from "../IntroSection/IntroSection";
import { initFluid, toggleColorMode } from "utils/canvasCursor";

import { useIsMobile } from "hook/useIsMobile";
import WarningModal from "components/UI/WarningModal";

interface OSProps {
  onRequireAuth: () => void;
}

const OS: React.FC<OSProps> = observer(({ onRequireAuth }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { t } = useTranslation();
  const auth0 = useAuth0();
  const notify = useNotification();
  const isMobile = useIsMobile();
  const [hasShownIntro, setHasShownIntro] = useState(false);

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [hasDismissedWarning, setHasDismissedWarning] = useState(false);

  const {
    backgroundUrl,
    showPreboot,
    isAuthenticated,
    debugMode,
    showAlertModal,
  } = userStore;

  useEffect(() => {
    if (isMobile && !hasDismissedWarning) {
      setIsWarningModalOpen(true);
    } else {
      setIsWarningModalOpen(false);
    }
  }, [isMobile, hasDismissedWarning]);

  const handleWarningModalClose = () => {
    setHasDismissedWarning(true);
    setIsWarningModalOpen(false);
  };

  useCustomCursor();
  useMagneticHover();

  const handleIntroDone = () => {
    setHasShownIntro(true);
    setTimeout(() => {
      console.log("FluidEnabled");
    }, 100);
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    initFluid(canvasRef.current);

    userStore.initNetworkStatus(
      process.env.REACT_APP_API_URL || "https://google.fr"
    );

    window.addEventListener("mousemove", function handle(e) {
      window.dispatchEvent(
        new MouseEvent("mousedown", {
          clientX: e.clientX,
          clientY: e.clientY,
        })
      );
      window.removeEventListener("mousemove", handle);
    });
  }, []);

  useEffect(() => {
    if (userStore.isOnline) {
      toggleColorMode("default");
    } else {
      toggleColorMode("alt");
    }
  }, [userStore.isOnline]);

  const handleLaunchApp = (app: AppDefinition) => {
    if (app.requiresBackend && !userStore.isAuthenticated) {
      userStore.setPendingBackendApp(app.appName);
      userStore.setShowAuthModal(true);
      return;
    }

    appStore.launchApp(app.appName);
  };

  const shouldShowIntro =
    !hasShownIntro && !userStore.showAuthModal && !userStore.isAuthenticated;

  return (
    <div className="h-screen w-screen relative overflow-hidden bg-black/[0.96]">
      {shouldShowIntro && <IntroSection onIntroDone={handleIntroDone} />}

      {debugMode && (
        <div className="absolute w-full pointer-events-none z-20">
          <DebugDisclaimer />
        </div>
      )}

      <canvas
        ref={canvasRef}
        id="fluid"
        className="w-full h-full block z-[2] relative opacity-60"
      />

      <div className="cursor" />

      <div className="absolute inset-0 bg-customGray/40 z-0"></div>
      <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#22dcd107_1px,transparent_1px),linear-gradient(to_bottom,#22dcd107_1px,transparent_1px)] bg-[size:160px_160px]" />

      {/* BACKGROUND */}
      <div className="absolute inset-0 z-0 flex items-center justify-center pointer-events-none">
        <h1
          className={`
          uppercase leading-none mt-0 lg:mt-6 
          text-[8rem] sm:text-[11rem] md:text-[14rem] lg:text-[16rem] xl:text-[18rem] 
          select-none font-bold bg-clip-text text-transparent bg-gradient-to-b
          ${
            userStore.isOnline
              ? "from-synkColor/50 to-synkColor/90"
              : "from-[#C20E4D] to-red-600"
          }
        `}
        >
          SYNK
        </h1>
      </div>
      <div
        className="
            absolute inset-0
            bg-cover bg-center bg-no-repeat
            bg-blend-multiply
            z-[1]
          "
        style={{
          backgroundImage: `url("${userStore.backgroundUrl}")`,
        }}
      ></div>

      <div className="absolute w-full flex justify-center z-30">
        <TopBar onRequireAuth={onRequireAuth} />
      </div>

      <WindowManager />

      <div className="z-50">
        <Dock onLaunchApp={handleLaunchApp} />
      </div>

      <WarningModal
        isOpen={isWarningModalOpen}
        onClose={handleWarningModalClose}
        title="Notice"
        message="This application is not optimized for screens of this size. You can continue to use it, but performance may be degraded."
      />

      {showAlertModal && (
        <AlertModalTokenExpire
          isOpen={showAlertModal}
          onClose={() => userStore.closeAlertModal()}
        />
      )}
    </div>
  );
});

export default OS;
