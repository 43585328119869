import { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { Button } from "components/UI/Button";
import { observer } from "mobx-react-lite";
import userStore from "stores/UserStore";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { PopupConfigOptions } from "@auth0/auth0-spa-js";
import { useNotification } from "./NotificationProvider";
import { Spotlight } from "components/UI/Spotlight";
import { BorderBeam } from "components/UI/borderBeam";
import { StarsBackground } from "components/UI/stars-background";
import { ShinyButton } from "./shiny-button";

const AuthRequiredModal = observer(() => {
  const {
    loginWithPopup,
    loginWithRedirect,
    isAuthenticated,
    isLoading: auth0Loading,
  } = useAuth0();
  const { t } = useTranslation();
  const notify = useNotification();
  const [error, setError] = useState<string | null>(null);

  const handleRedirectLogin = () => {
    loginWithRedirect();
  };

  const handleCloseModal = () => {
    userStore.setShowAuthModal(false);
    if (userStore.isLoading) {
      userStore.setIsLoading(false);
    }
  };

  const btn = (
    <p
      className="text-sm cursor-pointer font-medium text-white opacity-80 font-helveticaneue tracking-tight-4 gradient-stroke-less no-underline hover:underline hover:text-lightColor/90 hover:gradient-stroke"
      onClick={handleRedirectLogin}
    >
      {t("popup_alternative")}
    </p>
  );

  const handleLogin = async () => {
    try {
      userStore.setIsLoading(true);
      notify({
        message: t("popup_opened_title"),
        description: t("popup_opened_description"),
        btn: btn,
        duration: 10,
      });

      let width = 500;
      let height = 600;

      if (window.innerWidth < 768) {
        width = 390;
        height = 600;
      }

      const left = window.screenX + window.outerWidth - width;
      const top = window.screenY + 150;

      const popup = window.open(
        "",
        "auth0:authorize:popup",
        `width=${width},height=${height},left=${left},top=${top},resizable`
      );

      if (!popup) {
        throw new Error("Popup blocked");
      }

      const config: PopupConfigOptions = { popup };
      await loginWithPopup(undefined, config);
    } catch (error) {
      setError(t("popup_blocked_description"));
      notify({
        message: t("popup_blocked_title"),
        description: t("popup_blocked_description"),
        btn: btn,
        duration: 10,
        placement: "bottomRight",
        showProgress: true,
      });
      console.error("Erreur lors de l'ouverture de la popup de login :", error);
      userStore.setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!auth0Loading && !isAuthenticated) {
      userStore.setIsLoading(false);
    }
  }, [auth0Loading, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && userStore.showAuthModal) {
      userStore.handleSuccessfulAuth();
    }
  }, [isAuthenticated]);

  return (
    <Modal
      backdrop="opaque"
      isOpen={userStore.showAuthModal}
      onClose={handleCloseModal}
      classNames={{
        backdrop:
          "z-[9998] bg-gradient-to-t from-black/[0.96] to-bg-black/[0.26] backdrop-opacity-20",
        wrapper: "z-[9999]",
        body: "py-6",
        base: "backdrop-blur-dock border-small !shadow-custom rounded-xl",
        closeButton: "z-50 hover:bg-white/5 active:bg-white/10",
      }}
      onOpenChange={(open) => {
        if (!open) {
          handleCloseModal();
        }
      }}
    >
      <ModalContent>
        <Card className="p-6 py-2 pb-6 overflow-hidden relative bg-black/[0.96] backdrop-blur-dock">
          <StarsBackground />
          <BorderBeam duration={12} delay={9} className="rounded-xl" />
          <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:150px_150px]"></div>

          <Spotlight
            fill="#22dcd1"
            className="!w-[1000px] !h-[900px] !left-[245px]"
          />

          <div className="absolute inset-0 bg-customGray/10 h-full w-full pointer-events-none shadow-custom"></div>

          <div className="relative z-10">
            <div className="p-5">
              <div className="flex items-center gap-3">
                <Avatar
                  src="/images/app/SynkStore/StoreSynk.jpg"
                  className="bg-transparent"
                />
                <p className="text-large font-medium text-white">Synk OS</p>
              </div>
            </div>

            <div className="px-5 py-4">
              <div className="flex flex-col gap-2 px-2">
                <h2 className="text-sm text-white font-monospace tracking-tight-4">
                  {userStore.pendingBackendApp
                    ? t("auth_required_app")
                    : t("welcome")}
                </h2>
                <p className="text-sm text-white font-monospace tracking-tight-4 gradient-stroke">
                  {userStore.pendingBackendApp
                    ? t("auth_required_app_desc")
                    : t("connect_prompt")}
                </p>
              </div>
            </div>

            <div className="p-5 flex justify-end gap-2">
              <Button
                className="w-full border-small border-white/20 bg-white/10 text-white"
                size="default"
                variant="login"
                onClick={handleLogin}
                isLoading={userStore.isLoading}
                disabled={userStore.isLoading}
              >
                {userStore.isLoading ? t("connecting") : t("login")}
              </Button>
            </div>
          </div>
        </Card>
      </ModalContent>
    </Modal>
  );
});

export default AuthRequiredModal;
