import React from "react";
import BtnDate from "./components/BtnDate";
import BtnUserProfile from "./components/BtnUserProfile";
import { observer } from "mobx-react-lite";
import WatchIcon from "./components/WatchIcon";
import selectionStore from "stores/SelectionStore";
import {
  Button,
  Image,
  Popover,
  PopoverTrigger,
  Tooltip,
  PopoverContent,
  Input,
  Progress,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import appStore from "stores/AppStore";
import userStore from "stores/UserStore";

interface TopBarProps {
  onRequireAuth?: () => void;
}

const TopBar: React.FC<TopBarProps> = observer(({ onRequireAuth }) => {
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const {
    formattedTokenRemainingTime,
    tokenProgress,
    tokenColor,
    isGuestSession,
  } = userStore;

  const colorMap: Record<string, string> = {
    success: "#00C851",
    warning: "#FFC107",
    danger: "#FF4444",
  };
  const iconColor = colorMap[tokenColor] || "#3680FF";

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button !== 0) return;

    const interactiveElements = ["BUTTON", "A", "INPUT", "SELECT", "TEXTAREA"];
    if (interactiveElements.includes((e.target as HTMLElement).tagName)) {
      return;
    }

    e.preventDefault();
    selectionStore.startSelection(e.clientX, e.clientY);
  };

  const handleOpenSettings = () => {
    appStore.focusOrLaunchApp("Settings");
  };

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Erreur lors du passage en plein écran: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div
      className="fixed top-3 mx-auto w-[98%] px-2 py-1 h-12 flex items-center justify-between text-white z-10 animate-slideUp"
      style={{ userSelect: "none" }}
      onMouseDown={handleMouseDown}
    >
      <div className="hidden sm:flex items-center space-x-2">
        <BtnDate />
      </div>

      <div className="hidden sm:flex absolute left-1/2 transform -translate-x-1/2">
        {/* <BtnSynkLogo /> */}
      </div>

      <div className="flex sm:hidden flex-1 justify-start ml-4">
        {/* <BtnSynkLogo /> */}
      </div>

      <div className="flex items-center space-x-2">
        {/* ================ TOKEN EXPIRES BUTTON ================ */}
        <Tooltip
          closeDelay={0}
          content={t("token_tooltip")}
          delay={1500}
          offset={10}
          isDisabled={isPopoverOpen}
          classNames={{
            content: [
              "py-2 px-4",
              "text-white bg-gradient-to-br from-customGray/40 to-customGray/80",
            ],
          }}
          motionProps={{
            variants: {
              exit: {
                opacity: 0,
                transition: {
                  duration: 0.1,
                  ease: "easeIn",
                },
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: 0.15,
                  ease: "easeOut",
                },
              },
            },
          }}
        >
          <span>
            <Popover
              placement="bottom-end"
              showArrow
              isOpen={isPopoverOpen}
              onOpenChange={(open) => setIsPopoverOpen(open)}
              classNames={{
                content: [
                  "py-3 px-4 border border-default-200 w-[270px]",
                  "bg-customGray/60 backdrop-blur-dock border-small border-divider shadow-custom relative",
                ],
              }}
            >
              <PopoverTrigger>
                <Button
                  aria-label="Session time left"
                  variant="faded"
                  className="border-none opacity-60 flex flex-row"
                  data-magnetic
                  endContent={
                    <WatchIcon color={iconColor} width={20} height={20} />
                  }
                >
                  {Number(tokenProgress).toFixed(0)}%
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex flex-col w-full gap-2 p-2">
                  <p className="ml-[-5px] text-tiny text-foreground-500">
                    {t("session_expiration")}
                  </p>

                  <Input
                    label={t("token_expires_in")}
                    value={formattedTokenRemainingTime}
                    isReadOnly
                    disabled
                    className="max-w-xs mt-2"
                    classNames={{
                      inputWrapper: [
                        "group-data-[hover=true]:bg-default-100",
                        "hover:bg-default-100",
                      ],
                    }}
                    aria-label={t("token_expires_in")}
                  />

                  <Progress
                    size="md"
                    radius="md"
                    value={tokenProgress}
                    color={tokenColor}
                    showValueLabel={true}
                    className="mt-2"
                    aria-label={t("token_progress")}
                  />
                </div>
              </PopoverContent>
            </Popover>
          </span>
        </Tooltip>

        {/* ================ TOGGLE FULL SCREEN BUTTON ================ */}
        <Tooltip
          closeDelay={0}
          content={t("fullscreen_tooltip")}
          delay={1500}
          offset={10}
          classNames={{
            content: [
              "py-2 px-4",
              "text-white bg-gradient-to-br from-customGray/40 to-customGray/80",
            ],
          }}
          motionProps={{
            variants: {
              exit: {
                opacity: 0,
                transition: {
                  duration: 0.1,
                  ease: "easeIn",
                },
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: 0.15,
                  ease: "easeOut",
                },
              },
            },
          }}
        >
          <Button
            isIconOnly
            aria-label="Toggle fullscreen"
            variant="faded"
            className="border-none opacity-60"
            data-magnetic
            onClick={handleFullScreen}
          >
            <Image radius="none" width={20} src="/images/svg/G8.png" />
          </Button>
        </Tooltip>

        {/* ================ OPEN SETTINGS BUTTON ================ */}
        <Tooltip
          closeDelay={0}
          content={t("settings_tooltip")}
          delay={1500}
          offset={10}
          classNames={{
            content: [
              "py-2 px-4",
              "text-white bg-gradient-to-br from-customGray/40 to-customGray/80",
            ],
          }}
          motionProps={{
            variants: {
              exit: {
                opacity: 0,
                transition: {
                  duration: 0.1,
                  ease: "easeIn",
                },
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: 0.15,
                  ease: "easeOut",
                },
              },
            },
          }}
        >
          <Button
            isIconOnly
            aria-label="Open settings"
            variant="faded"
            className="border-none opacity-60"
            data-magnetic
            onClick={handleOpenSettings}
          >
            <Image radius="none" width={20} src="/images/svg/G11.png" />
          </Button>
        </Tooltip>

        <BtnUserProfile onRequireAuth={onRequireAuth} />
      </div>
    </div>
  );
});

export default React.memo(TopBar);
