import React from "react";

const SynkSmash: React.FC = () => {
  return (
    <div className="relative w-full h-full">
      <iframe
        src="https://smash.synk.ws/"
        title="Synk Smash"
        className="w-full h-full border-none"
        sandbox="allow-scripts allow-same-origin allow-popups allow-downloads allow-clipboard-write"
        allow="camera; microphone; fullscreen; clipboard-write"
      ></iframe>
    </div>
  );
};

export default SynkSmash;
