import React from "react";
import { motion } from "framer-motion";
import { Image, Avatar, Chip, Tooltip } from "@nextui-org/react";
import { Lock } from "lucide-react";
import clsx from "clsx";
import { useCountdown } from "hook/useCountdown";
import { useTranslation } from "react-i18next";

interface Props {
  app: any;
  onClick: (app: any) => void;
  instanceId?: string;
}

const ExpandableCardItem: React.FC<Props> = ({ app, onClick, instanceId }) => {
  const { t } = useTranslation();
  const prefix = instanceId ? `${instanceId}-` : "";
  const { releaseDate } = app || {};
  const { days, hours, minutes, seconds, isReleased } =
    useCountdown(releaseDate);
  const isLocked = !isReleased;

  const handleCardClick = () => {
    if (!isLocked) {
      onClick(app);
    }
  };

  return (
    <motion.div
      layoutId={`card-${prefix}${app.appName}`}
      onClick={handleCardClick}
      className={clsx(
        "relative rounded-2xl cursor-pointer bg-customGray/20",
        "hover:bg-customGray/40 hover:shadow-custom",
        "bg-[radial-gradient(circle_at_26%_0,rgba(178,198,255,0.20),rgba(243,243,244,0.05)_33%,rgba(255,255,255,0)_56%,rgba(57,59,76,0.09))]",
        "hover:bg-[radial-gradient(circle_at_26%_0,rgba(34,220,209,0.28),rgba(243,243,244,0.08)_33%,rgba(255,255,255,0)_56%,rgba(57,59,76,0.14))]",
        "shadow-[0_0_1px_0_rgba(0,0,0,0.25),_0_4px_8px_0_rgba(0,0,0,0.05),_0_16px_32px_0_rgba(0,0,0,0.05)]",
        "before:content-[''] before:absolute before:inset-0 before:-z-10",
        "flex flex-col w-full h-full border border-lightColor/10"
      )}
    >
      {app.requiresBackend && (
        <div className="absolute top-0 right-0 z-10">
          <div className="flex items-center gap-1 px-3 py-1.5 bg-gradient-to-r from-[#A5EEFD]/20 to-[#7EE7FC]/40 backdrop-blur-md rounded-tr-2xl rounded-bl-2xl border-l border-b border-[#7EE7FC]/20">
            <Lock className="w-3.5 h-3.5 text-[#7EE7FC]" />
            <span className="text-xs font-medium text-[#7EE7FC]">
              {t("auth_required_badge")}
            </span>
          </div>
        </div>
      )}
      <motion.div
        layoutId={`image-${prefix}${app.appName}`}
        className="flex p-4 items-center justify-center w-full rounded-t-2xl overflow-hidden"
      >
        {!isLocked ? (
          <div
            className={clsx(
              "relative p-8 flex items-center justify-center",
              "bg-customGray/50 rounded-2xl hover:bg-customGray/80",
              "bg-[radial-gradient(circle_at_26%_0,rgba(178,198,255,0.20),rgba(243,243,244,0.05)_33%,rgba(255,255,255,0)_56%,rgba(57,59,76,0.09))]",
              "hover:bg-[radial-gradient(circle_at_26%_0,rgba(34,220,209,0.28),rgba(243,243,244,0.08)_33%,rgba(255,255,255,0)_56%,rgba(57,59,76,0.14))]",
              "before:content-[''] before:absolute before:inset-0 before:-z-10",
              "before:bg-[#0a1319CC] before:rounded-inherit",
              "before:backdrop-filter before:backdrop-blur-[40px] before:backdrop-saturate-200",
              "flex flex-col w-full h-full border-none"
            )}
          >
            <Image
              src={app.image}
              alt={app.title}
              removeWrapper
              className="object-contain w-full max-w-[60%] h-auto"
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-[150px] sm:h-[200px] text-center p-4">
            <h2 className="text-xl font-bold text-white mb-2">Coming Soon!</h2>
            <div className="text-sm text-neutral-300">
              <span className="font-mono">
                {days}d {hours}h {minutes}m {seconds}s
              </span>
            </div>
          </div>
        )}
      </motion.div>
      <div className="p-6 py-4 flex flex-col items-start">
        {!isLocked && (
          <div className="flex gap-5">
            <Avatar isBordered radius="full" size="md" src={app.image} />
            <div className="flex flex-col gap-1 items-start justify-center">
              <motion.h4
                layoutId={`title-${prefix}${app.appName}`}
                className="text-lg font-semibold leading-none text-default-600"
              >
                {app.title}
              </motion.h4>
              <motion.h4
                layoutId={`description-${prefix}${app.appName}`}
                className="text-md tracking-tight text-default-400"
              >
                {app.developer || app.category}
              </motion.h4>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default ExpandableCardItem;
