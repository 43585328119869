import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Cover } from "components/UI/cover3";
import { Progress } from "@nextui-org/react";

const TurnOffScreen = observer(() => {
  return (
    <div className="fixed inset-0 bg-black z-[100000] flex flex-col items-center justify-center">
      <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#80808010_1px,transparent_1px),linear-gradient(to_bottom,#80808010_1px,transparent_1px)] bg-[size:154px_154px]"></div>

      <div className="flex w-full h-full relative flex-col items-center justify-center">
        <h1 className="text-2xl md:text-2xl lg:text-4xl font-semibold max-w-7xl mx-auto text-center mt-6 relative z-20 py-6 bg-clip-text text-transparent bg-gradient-to-b from-neutral-800 via-neutral-700 to-neutral-700 dark:from-neutral-800 dark:via-white dark:to-white">
          SYNK is shutting down, <br /> <Cover>please wait</Cover>
        </h1>
        <Progress
          isIndeterminate
          classNames={{
            track: "drop-shadow-md border border-default rounded-3xl",
            indicator:
              "bg-gradient-to-r from-white to-zinc-900 !duration-[7000s]",
          }}
          size="sm"
          aria-label="Loading..."
          className="max-w-md"
          color="success"
        />
      </div>
    </div>
  );
});

export default TurnOffScreen;
