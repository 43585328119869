import React from "react";
import { observer } from "mobx-react-lite";
import { useAuth0 } from "@auth0/auth0-react";

import userStore from "stores/UserStore";

import { Login } from "components/Login/Login";
import Preboot from "components/Preboot/Preboot";
import AuthRequiredModal from "components/UI/AuthRequiredModal";
import TurnOffScreen from "components/UI/TurnOffScreen";

import OS from "./components/OS/OS";

let prebootAlreadyShown = false;

const SynkOSv2: React.FC = observer(() => {
  const auth0 = useAuth0();
  const { showPreboot, showTurnOffScreen } = userStore;

  React.useEffect(() => {
    userStore.initializeAuth(auth0);
  }, [auth0.isAuthenticated]);

  const shouldShowPreboot =
    showPreboot && !userStore.showAuthModal && !prebootAlreadyShown;

  if (showTurnOffScreen) {
    return <TurnOffScreen />;
  }

  if (shouldShowPreboot) {
    return <Preboot />;
  }

  prebootAlreadyShown = true;

  return (
    <>
      <OS onRequireAuth={() => userStore.setShowAuthModal(true)} />
      <AuthRequiredModal />
    </>
  );
});

export default SynkOSv2;
