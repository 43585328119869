import React from "react";

const SynkCrypt: React.FC = () => {
  return (
    <div className="relative w-full h-full">
      <iframe
        src="https://synkcrypt.synk.ws"
        title="SynkCrypt"
        className="w-full h-full border-none"
        sandbox="allow-scripts allow-same-origin allow-popups allow-downloads"
        allow="camera; microphone; fullscreen"
      ></iframe>
    </div>
  );
};

export default SynkCrypt;
